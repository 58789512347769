import {Skeleton} from 'antd';
import {lazy, Suspense} from 'react';

import type {Props} from './App';

const LazyApp = lazy(() => import('./App'));
const AsyncLazyApp = (props: Props) => (
    <Suspense fallback={<Skeleton />}>
        <LazyApp {...props} />
    </Suspense>
);

export default AsyncLazyApp;
